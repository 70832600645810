/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable color-hex-length */
@use "@angular/material" as mat;

// Define color variables for use throughout the app
$primary-color: #56c98a;
$primary-light-color: #8cdcb7;
$primary-light-color-2: #c1e8da;
$primary-dark-color: #07b977;
$primary-contrast-color: #333333;

$secondary-color: #8cc9ee;
$secondary-light-color: #b4dcf4;
$secondary-super-light-color: #eefaf6;
$secondary-dark-color: #64b6e7;
$secondary-contrast-color: #333333;

$accent-color: #ff8c50;
$accent-light-color: #ffbb94;
$accent-dark-color: #ff5d0c;
$accent-contrast-color: #333333;

// Define custom color palettes using the variables
$primary-palette: mat.m2-define-palette(
  (
    50: #e3f8ef,
    100: #c2f0da,
    200: #a8e6cf,
    300: #8cdcb7,
    400: #72d3a0,
    
    500: $primary-color,
    600: $primary-dark-color,
    700: #36a468,
    800: #2a8657,
    900: #1e6746,
    A100: #b2ffc3,
    A200: #7fff91,
    A400: #4cff5f,
    A700: #1aff2d,
    contrast: (
      default: $primary-contrast-color,
    ),
  )
);

$secondary-palette: mat.m2-define-palette(
  (
    50: #f2f8fc,
    100: #dceef9,
    200: #c8e5f7,
    300: #b4dcf4,
    400: #a0d2f1,
    500: $secondary-color,
    600: $secondary-dark-color,
    700: #64b6e7,
    800: #50ade4,
    900: #3ca4e1,
    A100: #dceef9,
    A200: #c8e5f7,
    A400: #b4dcf4,
    A700: #a0d2f1,
    contrast: (
      default: $secondary-contrast-color,
    ),
  )
);

$accent-palette: mat.m2-define-palette(
  (
    50: #fff5ec,
    100: #ffebd8,
    200: #ffd3b6,
    300: #ffbb94,
    400: #ffa472,
    500: $accent-color,
    600: $accent-dark-color,
    700: $accent-dark-color,
    800: #e65400,
    900: #b24200,
    A100: #ffebd8,
    A200: #ffd3b6,
    A400: #ffbb94,
    A700: #ffa472,
    contrast: (
      default: $accent-contrast-color,
    ),
  )
);

// Dark theme colors
$dark-primary-color: #34a163;
$dark-primary-light-color: #56c98a;
$dark-primary-light-color-2: #8cdcb7;
$dark-primary-dark-color: #22794b;
$dark-primary-contrast-color: #ffffff;

$dark-secondary-color: #7bb5d8;
$dark-secondary-light-color: #9acceb;
$dark-secondary-super-light-color: #d3e8f4;
$dark-secondary-dark-color: #5890b0;
$dark-secondary-contrast-color: #ffffff;

$dark-accent-color: #f97738;
$dark-accent-light-color: #ffa870;
$dark-accent-dark-color: #c75017;
$dark-accent-contrast-color: #ffffff;

$dark-background-color: #121212;
$dark-surface-color: #1e1e1e;
$dark-border-color: #333333;

// Define custom palettes for the dark theme
$dark-primary-palette: mat.m2-define-palette(
  (
    50: #1e3027,
    100: #2a4735,
    200: #345f45,
    300: #3f784f,
    400: #4a915a,
    500: $dark-primary-color,
    600: #2e8c57,
    700: $dark-primary-dark-color,
    800: #1a5e3b,
    900: #0e3923,
    A100: #56c98a,
    A200: #8cdcb7,
    A400: #c1e8da,
    A700: #e3f8ef,
    contrast: (
      default: $dark-primary-contrast-color,
    ),
  )
);

$dark-secondary-palette: mat.m2-define-palette(
  (
    50: #1a252b,
    100: #233441,
    200: #2d4557,
    300: #37576e,
    400: #406883,
    500: $dark-secondary-color,
    600: #6a9fbb,
    700: $dark-secondary-dark-color,
    800: #437491,
    900: #2a536d,
    A100: #9acceb,
    A200: #c8e5f7,
    A400: #dceef9,
    A700: #f2f8fc,
    contrast: (
      default: $dark-secondary-contrast-color,
    ),
  )
);

$dark-accent-palette: mat.m2-define-palette(
  (
    50: #2b1b13,
    100: #3d2518,
    200: #4f311d,
    300: #613d22,
    400: #724926,
    500: $dark-accent-color,
    600: #e66e34,
    700: $dark-accent-dark-color,
    800: #9f3610,
    900: #6d240a,
    A100: #ffa870,
    A200: #ffbb94,
    A400: #ffd3b6,
    A700: #fff5ec,
    contrast: (
      default: $dark-accent-contrast-color,
    ),
  )
);

$dark-theme-background-palette: (
  background: $dark-background-color,
  surface: $dark-surface-color,
  border: $dark-border-color,
);

$heading-font-family: "Poppins", sans-serif;
$regular-font-family: "Poppins", sans-serif;
