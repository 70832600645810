@use "variables" as var;

.action-link {
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  min-width: 0;
  color: var.$primary-color !important; /* Primary color for the text */

  span {
    font-weight: 600;
    font-size: 14px;
    text-decoration: underline;
  }

  mat-icon {
    margin-left: 4px; /* Space between text and icon */
    color: var.$primary-color; /* Icon color */
    font-size: 16px;
    text-decoration: none;
  }
}

.primary-naked-md-button {
  border-radius: 18px !important; /* Add more border radius for rounded corners */
  background-color: transparent !important;
  padding: 12px 0 !important; /* Increase the padding for a larger button */
  width: 100% !important; /* Make the button span the full card width */
  max-width: 180px;
  color: var.$primary-color !important; /* Set the font color to white */
  font-weight: bold !important; /* Optional: make the text bold */
  font-size: 14px !important;
}

.primary-full-md-button {
  border-radius: 18px !important; /* Add more border radius for rounded corners */
  background-color: var.$primary-color !important;
  padding: 12px 0 !important; /* Increase the padding for a larger button */
  width: 100% !important; /* Make the button span the full card width */
  max-width: 180px;
  color: white !important; /* Set the font color to white */
  font-weight: bold !important; /* Optional: make the text bold */
  font-size: 14px !important;
}

.primary-stroked-md-button {
  border: solid 2px var.$primary-color !important;
  border-radius: 18px !important; /* Add more border radius for rounded corners */
  background-color: white !important;
  padding: 12px 0 !important; /* Increase the padding for a larger button */
  width: 100% !important; /* Make the button span the full card width */
  max-width: 180px;
  color: var.$primary-color !important; /* Set the font color to white */
  font-weight: bold !important; /* Optional: make the text bold */
  font-size: 14px !important;
}

.primary-full-lg-button {
  border-radius: 18px !important; /* Add more border radius for rounded corners */
  background-color: var.$primary-color !important;
  padding: 24px 0 !important; /* Increase the padding for a larger button */
  width: 100% !important; /* Make the button span the full card width */
  max-width: 280px;
  color: white !important; /* Set the font color to white */
  font-weight: bold !important; /* Optional: make the text bold */
  font-size: 18px !important;
}

.load-more-button {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  .nav-button-container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 8px;
    background-color: var.$primary-light-color-2;
    padding: 16px;
    width: 100%;
    text-align: left;
  }

  .slot-content {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: white;
    text-align: left;
  }

  .top-svg-container {
    position: absolute;
    top: -32px; /* Adjust to make the SVG stick out */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .bottom-svg-container {
    position: absolute;
    bottom: -32px; /* Adjust to make the SVG stick out */
    left: 50%;
    transform: translateX(-50%) rotate(180deg); /* Flip the SVG upside down */
    z-index: 1;
  }

  .arrow {
    position: absolute;
    top: 0; /* Center the arrow inside the SVG */
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; /* Ensure the arrow is on top of the SVG */
    color: white;
    font-size: 24px;
  }

  .slot-details {
    //padding-left: 35px;
    width: 100%; /* Adjust margin to fit below the SVG */
  }
}
